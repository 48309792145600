<template>
  <div v-if="showLogin">
    <div class="dltc_box"></div>
    <div class="dltc_box1">
      <div class="dltc_box1_main">
        <div class="dltc_box1_maint">
          <h4>登录</h4>
          <button class="Pop_btn" style="top: 5px;" @click="closePop()">
            <img src="../../images/ts_close.png" />
          </button>
        </div>
        <div class="dltc_box1_mainm">
          <div class="login01" id="loginPhone">
            <div class="login01A">
              <input
                type="text"
                v-model="loginPhone"
                placeholder="请输入邮箱/手机号"
              />
            </div>
            <p class="errorts"></p>
          </div>
          <div class="login01" id="loginPass">
            <div class="login01B">
              <input
                type="password"
                v-model="loginPass"
                placeholder="请输入正确密码"
              />
            </div>
            <p class="errorts"></p>
          </div>
          <div class="login02">
            <div class="login01C" id="logincode">
              <input
                type="text"
                v-model="loginCode"
                placeholder="请输入验证码"
              />
            </div>
            <div class="yanzhengma" @click="createCode()">
              <span class="J_codeimg1">{{ checkCode }}</span>
            </div>
            <p class="errorts"></p>
          </div>
          <div class="login_con3 tclogin_con3">
            <div class="login_lileft">
              <label>
                <input type="checkbox" v-model="issave" />
                <p style="color: #2a1708;">保存密码</p>
              </label>
            </div>
            <div class="login_liright">
              <a
                href="javascript:void(0)"
                @click="toForgetPass()"
                style="color: #2a1708;"
                >忘记密码？</a
              >
            </div>
          </div>
          <div class="login_a login_3">
            <a href="javascript:void(0)" @click="userLogin()">登 录</a>
            <span
              >第三方登录：
              <a href="javascript:void(0)" @click="toClientLogin()">
                <img src="../../images/disanfang.png" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import CheckInService from "../../api/CheckInService.js";
import MD5 from "js-md5";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      checkCode: "",
      loginPhone: "", //登录手机号
      loginPass: "", //登录密码
      loginCode: "", //验证码
      issave: false,
    };
  },
  props: ["showLogin"],
  methods: {
    createCode() {
      //生成验证码
      var code = "";
      var codeLength = 4; //验证码的长度
      var random = new Array(
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z"
      ); //随机数
      for (var i = 0; i < codeLength; i++) {
        var index = Math.floor(Math.random() * 36);
        code += random[index];
      }
      this.checkCode = code;
    },
    userLogin() {
      //登录
      var that = this;
      if (that.loginPhone.trim() == "") {
        $("#loginPhone").addClass("error");
        $("#loginPhone .errorts").text("请输入邮箱/手机号");
      } else if (!/^1[3456789]\d{9}$/.test(that.loginPhone.trim())) {
        $("#loginPhone").addClass("error");
        $("#loginPhone .errorts").text("请输入正确格式的邮箱/手机号");
      } else if (that.loginPass.trim() == "") {
        $("#loginPass").addClass("error");
        $("#loginPass .errorts").text("请输入密码");
        $("#loginPhone").removeClass("error");
        $("#loginPhone .errorts").text("");
      } else if (
        that.loginPass.trim().length < 6 ||
        that.loginPass.trim().length > 20
      ) {
        $("#loginPass").addClass("error");
        $("#loginPass .errorts").text("请输入6-20字符的密码");
        $("#loginPhone").removeClass("error");
        $("#loginPhone .errorts").text("");
      } else if (that.loginCode != that.checkCode) {
        that.loginCode = "";
        that.createCode();
        $("#loginPass").removeClass("error");
        $("#loginPass .errorts").text("");
        $("#loginPhone").removeClass("error");
        $("#loginPhone .errorts").text("");
      } else {
        if (that.issave) {
          //是否保存密码
          that.setCookie("user", that.loginPhone.trim(), 7); //保存帐号到cookie，有效期7天
          that.setCookie("pswd", that.encrypt(that.loginPass.trim()), 7); //保存密码到cookie，有效期7天
          that.setCookie("issave", that.issave, 7);
        } else {
          that.delCookie("user");
          that.delCookie("pswd");
          that.delCookie("issave");
        }
        CheckInService.UserLogin(
          that.loginPhone.trim(),
          MD5(that.loginPass.trim()),
          sessionStorage.getItem("AddressIP")
        ).then((res) => {
          if (res.Tag == 1) {
            var code = "";
            var codeLength = 10; //验证码的长度
            var random = new Array(
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              "a",
              "b",
              "c",
              "d",
              "e",
              "f",
              "g",
              "h",
              "i",
              "j",
              "k",
              "l",
              "m",
              "n",
              "o",
              "p",
              "q",
              "r",
              "s",
              "t",
              "u",
              "v",
              "w",
              "x",
              "y",
              "z"
            ); //随机数
            for (var i = 0; i < codeLength; i++) {
              var index = Math.floor(Math.random() * 36);
              code += random[index];
            }

            if (res.Result.UserType == 1) {
              // 判断是否是机构用户
              // 机构用户注册在线状态
              CheckInService.LogoutMechanicalUserOnline(
                res.Result.UserType,
                code
              ).then((resp) => {
                if (resp.Tag === 1) {
                  window.localStorage.setItem("DHToken", code);
                  window.sessionStorage.setItem("orgUserOnline", true);
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }
              });
            } else {
              // 普通用户注册在线状态
              CheckInService.RegisterUserOnline(res.Result.ID, code).then(
                (msg) => {
                  if (msg.Tag == 1) {
                    window.localStorage.setItem("DHToken", code);
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  }
                }
              );
            }

            // 目前后台对vip用户的类型改变不了，先通过vip到期时间判断是否是vip用户
            if (res.Result.ServiceDate) {
              let ServiceDate = res.Result.ServiceDate.replace(
                /-/g,
                "/"
              ).replace("T", " ");
              let vipTime = new Date(ServiceDate).getTime();
              if (vipTime > Date.now()) {
                // 会员没有过期，改变为会员类型
                window.localStorage.setItem("DHServiceType", 1);
                Vue.prototype.$ServiceType = 1;
              }
            }

            window.localStorage.setItem("DHuserID", res.Result.ID);
            window.localStorage.setItem("DHuserType", res.Result.UserType);
            window.localStorage.setItem(
              "DHServiceType",
              res.Result.ServiceType
            );
            Vue.prototype.$UserID = res.Result.ID;
            Vue.prototype.$userType = res.Result.UserType;
            Vue.prototype.$ServiceType = res.Result.ServiceType;
            that.showLogin = false;
          } else {
            that.$alert(res.Message, "提示", {
              confirmButtonText: "确定",
            });
            that.loginCode = "";
            that.createCode();
          }
        });
      }
    },
    closePop() {
      //关闭弹窗
      this.showLogin = false;
    },
    toForgetPass() {
      //忘记密码
      this.$router.push({
        path: "/ForgetPassword",
      });
    },
    toClientLogin() {
      window.open(
        "http://sso.ckrsc.com/oauth/authorize?client_id=b2cd5dd5f5af4f738e3b43236dd39c8f&response_type=code&scope=user_info&state=CKRSC&redirect_uri=http://dunhuang.gseph.xyz/OauthCallback.aspx"
      );
    },
    setCookie(name, value, day) {
      //设置cookie
      var date = new Date();
      date.setDate(date.getDate() + day);
      document.cookie = name + "=" + value + ";expires=" + date;
    },
    getCookie(name) {
      //获取cookie
      var reg = RegExp(name + "=([^;]+)");
      var arr = document.cookie.match(reg);
      if (arr) {
        return arr[1];
      } else {
        return "";
      }
    },
    delCookie(name) {
      //删除cookie
      this.setCookie(name, null, -1);
    },
    encrypt(value) {
      let salt = "pwax"; // 加密时的盐
      return Base64.encode(salt + value);
    },
    decrypt(value) {
      let salt = "pwax";
      return Base64.decode(value).substr(salt.length);
    },
  },
  mounted() {
    this.createCode();
    var that = this;
    if (that.getCookie("user") && that.getCookie("pswd")) {
      that.loginPhone = that.getCookie("user");
      that.loginPass = that.decrypt(that.getCookie("pswd"));
      that.issave = that.getCookie("issave");
    } else {
      that.loginPhone = "";
      that.loginPass = "";
      that.issave = false;
    }
    document.onkeydown = function(event) {
      var e = event || window.event || arguments.callee.caller.arguments[0];
      if (e && e.keyCode == 13) {
        // enter 键
        that.userLogin();
      }
    };
  },
};
</script>

<style></style>
